textarea {
	font: inherit;
}

input,
span[contenteditable="true"],
textarea {
	border-radius: $border-radius;
	border: 1px solid hsl(0, 0%, 80%);
	padding: 8px;
	display: block;
	box-sizing: border-box;
	font: inherit;
}

input:read-only,
span[contenteditable="true"]:read-only,
textarea:read-only {
	background-color: rgba(0, 0, 0, 0.08);
}

input:focus,
span[contenteditable="true"]:focus,
textarea:focus {
	outline: none;
	/* hack to increase the border width with 1px, and at the same time avoid increasing size of text box and jumping elements */
	border: 1px solid #2684ff;
	box-shadow: 0 0 0 1px #2684ff;
}

span[contenteditable="true"] {
}

[data-placeholder]:empty:before {
	content: attr(data-placeholder);
	color: #888;
	font-style: italic;
}

.input-with-label {
	display: flex;

	input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		width: 100px !important;
		padding: 6px;
	}

	div {
		border: 1px solid hsl(0, 0%, 80%);
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border-left: 0;
		display: flex;
		align-items: center;
		padding: 0 4px;

		svg {
			width: 22px;
			height: 22px;
		}
	}
}

.input-with-search {
	position: relative;

	input {
		padding-right: 32px;
	}
	svg {
		position: absolute;
		right: 6px;
		top: 0;
		bottom: 2px;
		margin: auto;
		width: 20px;
	}
}
