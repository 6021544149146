/* Defines the skeleton of the website (how the side menu, header and content are positioned)*/

body,
html {
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
	color: var(--default-text-color);
}

.side-menu {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: var(--side-menu-width);
	border-right: 1px solid var(--separator-line-color);
	box-sizing: border-box;
	overflow-y: auto;
}

// The main container contains the header and scrollable content. It starts where the menu finishes.
.main {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: var(--side-menu-width);
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		min-height: 64px;
		border-bottom: 1px solid var(--separator-line-color);
		padding: 0 24px 0px 64px;
	}

	.content {
		flex: 1;
		overflow-y: scroll;
		padding: 64px;
		background-color: #f9f9f9;
	}
}
