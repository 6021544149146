.badge {
	border-radius: var(--border-radius);
	font-size: 0.9rem;
	color: white;
	font-weight: 700;
	display: inline-block;
	padding: 2px 6px;
}

//the colors for the description badges are defined in DescriptionStatusBadge.tsx (because they are also used in the charts)
.badge.green {
	background-color: rgb(76, 175, 80);
}

.badge.grey {
	background-color: #9e9e9e;
}

.badge.yellow {
	background-color: #ff8f00;
}

.badge.blue {
	background-color: #2196f3;
}
