.side-menu {
	display: flex;
	flex-direction: column;

	.logo {
		width: 50%;
		display: block;
		margin-top: 32px;
		margin-bottom: 24px;
		margin-left: 40px;
	}

	.content {
		flex: 1;

		.side-menu-header {
			padding: 0 24px;
			margin: 4px 16px;
			margin-top: 32px;
			box-sizing: border-box;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 0.95rem;
			color: rgba(0, 0, 0, 0.55);
		}

		.menu-element {
			display: block;
			padding: 12px 24px;
			box-sizing: border-box;
			font-weight: 500;
			font-size: 1.1rem;
			color: var(--default-text-color);
			margin: 0 16px;
			border-radius: 10px;
			-moz-user-select: none;
			-webkit-user-select: none;
		}

		.link {
			@extend .menu-element;
			text-decoration: none;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.12);
			}

			&.active {
				background-color: #2962ff21;
				color: var(--primary-color);
			}
		}

		//a small grey banner, used mostly if the teacher doesn't have any courses
		.nothing-to-display {
			@extend .menu-element;
			cursor: default;
		}
	}

	.date {
		text-align: center;
		margin-bottom: 8px;
	}
}
