.comment-feed {
	display: flex;
	flex-direction: column;
	width: 100%;

	.title {
		font-size: 1.2rem;
		color: black;
		margin-bottom: 16px;
	}

	.comment-list {
		flex: 1;
		overflow-y: auto;
	}

	.add-comment {
		overflow-y: auto;
		display: flex;
		align-items: center;
		span {
			flex: 1;
		}
		.send-button {
			color: #1e88e5;
			cursor: pointer;
			border-radius: var(--border-radius);
			width: 38px;
			height: 38px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 4px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.08);
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.12);
			}
		}
	}
}
