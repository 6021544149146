.profile-colored-circle {
	color: white;
	font-weight: 500;
	border-radius: 50%;
	text-align: center;
	overflow: hidden;
}
.card {
	flex-wrap: wrap;
}
.info-text {
	margin-top: 4px;
	font-size: 0.9rem;
	color: #666;
}

.info-text.clickable:hover {
	cursor: pointer;
	text-decoration: underline;
}

.label {
	color: black;
	font-weight: 500;
	margin-bottom: 4px;
}

.react-datepicker-wrapper {
	width: 100%;
}

.loading {
	display: flex;
	justify-content: center;

	div {
		width: 1rem;
		height: 1rem;
		margin: 2rem 0.3rem;
		background: var(--primary-color);
		border-radius: 50%;
		animation: 0.9s bounce infinite alternate;

		&:nth-child(2) {
			animation-delay: 0.3s;
		}

		&:nth-child(3) {
			animation-delay: 0.6s;
		}
	}
}
input[type=number]{
	display: inline-block;
	text-align: right;
}

@keyframes bounce {
	to {
		opacity: 0.3;
		transform: translate3d(0, -1rem, 0);
	}
}

.sortable-list-item {
	min-width: 250px;
	padding: 10px 20px;
	margin-bottom: 8px;
	border-radius: $border-radius;
	background-color: rgba(0, 0, 0, 0.08);
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	cursor: grab;
	position: relative;

	.btn {
		position: absolute;
		right: 8px;
		top: 4px;
		bottom: 0;
		margin: auto;
	}
}

.sk-chase {
	width: 22px;
	height: 22px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
	display: inline-block;
}

.sk-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
	content: "";
	display: block;
	width: 30%;
	height: 30%;
	background-color: $blue;
	border-radius: 100%;
	animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase.blue .sk-chase-dot:before {
	background-color: var(--primary-color);
}

.sk-chase-dot:nth-child(1) {
	animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
	animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
	animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
	animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
	animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
	animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
	animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
	animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
	animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
	animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
	animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
	animation-delay: -0.6s;
}

@keyframes sk-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}

.progressBar {
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-top: 16px;
	margin-bottom: 16px;
	font-weight: 500;
	cursor: default;

	.checkpoint {
		padding: 4px 8px;
		border-radius: $border-radius;
		box-shadow: inset 0px 0px 0px 3px rgba(0, 0, 0, 0.24);
		display: flex;
		align-items: center;
		min-height: 32px;
		box-sizing: border-box;

		svg {
			display: none;
			stroke-width: 4;
			width: 16px;
			margin-right: 4px;
		}

		&.active {
			background-color: rgb(76, 175, 80);
			color: white;
			box-shadow: none;

			svg {
				display: inline-block;
			}
		}
	}

	.line {
		flex: 1;
		height: 5px;
		background-color: rgba(0, 0, 0, 0.24);

		&.active {
			background-color: rgb(76, 175, 80);
		}
	}
}

.card {
	background-color: white;
	border-radius: $border-radius;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.16);
	padding: 1.25rem;
	display: inline-block;
	.title {
		font-size: 1.4rem;
		margin-top: -8px;
		margin-bottom: 8px;
	}
}

.error-popup {
	position: fixed;
	padding: 12px 24px;
	padding-left: 48px; //for icon
	color: white;
	background-color: $red;
	border-radius: $border-radius;
	box-shadow: 0 0 10px $red;
	left: 50%;
	transform: translateX(-50%);
	max-width: 500px;
	animation: errorPopupAnimation 5s forwards;

	svg {
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
	}
}

@keyframes errorPopupAnimation {
	0% {
		bottom: -100px;
	}
	5% {
		bottom: 32px;
	}
	95% {
		bottom: 32px;
	}
	100% {
		bottom: -100px;
	}
}

.independent-label {
	margin-bottom: 4px;
	color: black;
	font-weight: bold;
}

.text-box {
	background-color: rgba(0, 0, 0, 0.025);
	flex: 1;
	padding: 8px;
	border-radius: $border-radius;
	white-space: pre-wrap;
	min-height: 0;
	overflow-y: auto;
}

.flexRow1 {
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: 0;
}
.flexCol1 {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;
}
.flexRowEnd {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.flexRowBetween {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

// the row above the table with select filters and buttons, present almost on all the pages with tables.
.controls-above-table {
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	// container for the filter select
	.filter {
		width: 220px;
		&:not(:first-child) {
			// add left margins to all fitlers expect the first one
			margin-left: 8px;
		}
	}

	// contains the buttons, to the rigth of the table
	.actions {
		flex: 1;
		text-align: right;
		margin-left: 8px; // just in case the actions touch the filters, add some margin
	}
}

.warning {
	padding: 8px 12px;
	border-radius: $border-radius;
	background-color: rgb(255, 214, 214);
	color: rgb(216, 41, 41);
	font-weight: 500;
	border: 1px solid rgb(255, 102, 102);
}

// wrapper for date picker to give it 100 width
.datePicker100Width > div > input {
	width: 100%;
}
