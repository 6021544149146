.error-banner {
	background-color: rgb(255, 208, 208);
	font-weight: 500;
	color: red;
	border-radius: var(--border-radius);
	padding: 8px 24px;
	text-align: center;
	margin-bottom: 8px;
}

.error-banner.short {
	padding: 8px 8px;
	max-width: 700px;
	text-align: left;
}

.info-banner {
	background-color: #2962ff21;
	font-weight: 500;
	color: var(--primary-color);
	border-radius: var(--border-radius);
	padding: 8px 8px;
	text-align: left;
	margin-bottom: 8px;
	max-width: 700px;
}

.nothing-to-display-banner {
	padding: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	text-align: center;
	color: rgba(0, 0, 0, 0.48);
	background-color: rgba(0, 0, 0, 0.08);
	border-radius: var(--border-radius);
	margin-bottom: 8px;
}
