:root {
	--separator-line-color: rgba(0, 0, 0, 0.12);
	--border-radius: 5px;
	--default-text-color: #333;
	--primary-color: #2962ff;
	--side-menu-width: 250px;
}

$border-radius: 5px;

$blue: #2962ff;
$blue-dark: #1851ee;
$blue-darker: rgb(17, 72, 226);
$blue-disabled: #7197ff;

$green: #4caf50;
$green-dark: #429b45;
$green-darker: #3a8a3d;
$green-disabled: #72c475;

$red: #ff1e1e;
$red-dark: #f01212;
$red-darker: #da0909;
$red-disabled: #fd7171;
