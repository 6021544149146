.btn {
	//styles for a normal button
	border-radius: var(--border-radius);
	padding: 0 16px;
	text-decoration: none;
	color: var(--default-text-color);
	border: none;
	background-color: transparent;
	cursor: pointer;
	font: inherit;
	font-weight: 500;
	height: 38px;
	display: inline-flex;
	align-items: center;
	white-space: nowrap; //avoid 2 line text on buttons if it doesn't fit
	outline: none;
	user-select: none;

	//hovering a button without any color (transparent button)
	&:hover {
		background-color: rgba(0, 0, 0, 0.08);
	}

	//clicking a button without any color (transparent button)
	&:active {
		background-color: rgba(0, 0, 0, 0.12);
	}

	&.green {
		color: white;
		background-color: $green;

		&:not(.text):not(.outline):not(.icon) {
			&:hover:not(.disabled) {
				background-color: $green-dark;
			}

			&:active:not(.disabled) {
				background-color: $green-darker;
			}

			&.disabled {
				cursor: default;
				background-color: $green-disabled;
			}
		}
	}

	&.blue {
		background-color: $blue;
		color: white;

		&:not(.text):not(.outline):not(.icon) {
			&:hover:not(.disabled) {
				background-color: $blue-dark;
			}

			&:active:not(.disabled) {
				background-color: $blue-darker;
			}

			&.disabled {
				cursor: default;
				background-color: $blue-disabled;
			}
		}
	}

	&.red {
		background-color: $red;
		color: white;

		&:not(.text):not(.outline):not(.icon) {
			&:hover {
				background-color: $red-dark;
			}

			&:active {
				background-color: $red-darker;
			}

			&.disabled {
				cursor: default;
				background-color: $red-disabled;
			}
		}
	}

	$iconSpacingRight: 8px;

	//icon inside a normal button
	svg {
		width: 24px;
		height: 24px;
		margin-right: $iconSpacingRight;
		margin-left: -4px;
	}

	&.icon-at-end {
		svg {
			margin-left: $iconSpacingRight;
			margin-right: -4px;
		}
	}

	//loading indicator
	.sk-chase {
		width: 24px;
		height: 24px;
		margin-right: $iconSpacingRight;
		margin-left: -4px;
	}

	.sk-chase-dot:before {
		background-color: #fff;
	}

	&.outline {
		&.blue {
			color: $blue;
			border: 1px solid $blue;
			background-color: transparent;
		}

		&.green {
			color: $green;
			background-color: transparent;
			border: 1px solid $green;

			&:hover {
				background-color: rgba($green-dark, 0.15);
			}

			&:active {
				background-color: rgba($green-darker, 0.2);
			}
		}

		&.red {
			color: $red;
			border: 1px solid $red;
			background-color: white;

			&:hover {
				background-color: #ff1e1e2d;
			}

			&:active {
				background-color: #ff1e1e4d;
			}
		}

		&.disabled,
		&.disabled:hover,
		&.disabled:active {
			color: grey;
			border-color: grey;
			cursor: default;
			background-color: transparent;
		}
	}

	//text button (clickable text), also can contain an icon
	&.text {
		padding: 0px 4px;
		font-weight: bold;
		background-color: transparent;
		outline: none;
		height: 24px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.12);
		}

		//icon inside a text button
		svg {
			margin-left: 0; //text buttons don't have padding, so cancel the left margin that pull icon to left
			width: 22px;
			height: 22px;
			stroke-width: 2.5;
		}

		//the loading indicator
		.sk-chase {
			width: 18px;
			height: 18px;
		}
		.sk-chase-dot:before {
			background-color: #2962ff;
		}

		&.blue {
			color: $blue;

			&:hover {
				color: $blue-dark;
			}

			&:active {
				color: $blue-darker;
			}
		}

		&.red {
			color: $red;

			&:hover {
				color: $red-dark;
			}

			&:active {
				color: $red-darker;
			}
		}
	}

	// Icon that behaves like button. When it is colored, only the icon is colored, the button will still have a grey background when hovered.
	&.icon {
		background-color: transparent;
		outline: none;
		padding: 0 8px;

		//icon
		svg {
			margin: 0; //cancel margins from other button classes
			stroke-width: 2.25;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.12);
		}

		&.sm {
			padding: 0 2px;
			svg {
				margin: 0; //cancel margins from other button classes
				width: 24px;
				height: 24px;
				stroke-width: 2;
			}
		}

		&.green {
			svg {
				color: $green;
			}
		}

		&.red {
			svg {
				color: $red;
			}
		}
	}

	//small button
	&.sm {
		padding: 0 8px;
		font-weight: 600;
		height: 26px;

		//icon inside small button
		svg {
			width: 20px;
			height: 20px;
			margin-right: 4px;
			stroke-width: 2.5;
		}
	}

	//block button, fills available width
	&.block {
		width: 100%;
		justify-content: center;
	}
}

.tab-buttons {
	display: inline-flex;
	border: 1px solid #2962ff;
	font-weight: 600;
	border-radius: var(--border-radius);
	user-select: none;

	div {
		padding: 8px 16px;
		color: #2962ff;
		min-width: 100px;
		text-align: center;

		&:not(:last-child) {
			border-right: 1px solid $blue;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}

		&:not(.active):hover {
			cursor: pointer;
		}

		&.active:hover {
			cursor: default;
		}

		&.active {
			background-color: $blue;
			color: white;
		}
	}
}
