// The modal is fixed and fills all screen. It uses display flex to veritcally and horizontally center its content.
.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(6px);
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;

	&.hidden {
		display: none;
	}

	.close-button {
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;
		border-radius: var(--border-radius);
		width: 28px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: rgba(0, 0, 0, 0.08);
		}

		&:active {
			background-color: rgba(0, 0, 0, 0.12);
		}
	}

	> .content {
		display: inline-block;
		position: relative;
		background-color: white;
		border-radius: var(--border-radius);
		padding: 24px;
		box-sizing: border-box;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
		max-height: 90vh;

		&.flex {
			display: flex;
		}

		&.hidden {
			display: none;
		}

		> .title {
			color: black;
			font-size: 1.5rem;
			margin-bottom: 24px;
		}
	}
}
