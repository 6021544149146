th {
	/*  cancel some styles created by the browser */
	border-spacing: 0;
	padding: 0;
	text-align: left;
}

table.table {
	border-radius: var(--border-radius);
	border-collapse: collapse;
	background-color: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.16);
	width: 100%;

	tr {
		border-bottom: 1px solid var(--separator-line-color);
	}

	tbody tr:last-child {
		// hide last row in table body border so we don't get a double border from the table also
		border: none;
	}

	td,
	th {
		padding: 12px 16px;
	}

	&.interactible tbody tr:hover {
		/* interactible table is a table which rows can be clicked */
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.08);
	}
}

.table-with-inputs {
	width: 100%;
	margin-bottom: 16px;

	td:first-child {
		/* First td contains the label of the input */
		vertical-align: baseline;
		font-weight: 500;
		white-space: nowrap;
		text-align: right;
		padding-right: 8px;
		min-width: 80px;
	}

	&.vertically-centered-labels,
	.vertically-centered-label {
		td:first-child {
			padding-top: 20px;
		}
	}

	td:last-child {
		width: 100%;

		textarea,
		input[type="text"] {
			width: 100%;
		}
	}

	td {
		padding-top: 12px;
	}

	input[type="text"],
	input[type="password"] {
		width: 100%;
	}

	input[type="checkbox"] {
		margin-top: 10px;
	}

	tr.title {
		td {
			padding-top: 0;
			padding-bottom: 0;
			font-weight: 500;
			font-size: 1.2rem;
		}
	}

	tr.title:not(:first-child) {
		td {
			padding-top: 24px;
		}
	}
}

// Scrollable table, usually used inside a modal
table.scrollable {
	box-shadow: 0 0 0 1px var(--separator-line-color); //hack to achieve a round outline. Table border and border radius don't work well toghether.

	tbody {
		display: block;
		height: 50vh;
		overflow-y: scroll;
	}

	thead,
	tbody tr {
		display: table;
		width: 100%;
		table-layout: fixed;
	}

	thead {
		width: calc(100% - 1em);
	}
}

tr.interactible:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.04);
	input {
		cursor: pointer;
	}
}
